<header-nav></header-nav>
<div class="mh-auto min-vh-100 page-content">
    <router-outlet></router-outlet>
</div>
<footer class="footer mt-2">
    <div class="container">
        <br />
        <div class="row">
            <div class="col g-0 text-center">
                <a href="https://www.mnwd.com" alt="" target="_blank">
                    <img
                        src="assets/main/logos/MNWD_Logo_White_Primary_Preferred_Logo.png"
                        style="height: 55px"
                    />
                </a>
            </div>
            <div class="col g-0 text-center" style="margin-top: 5px">
                <a href="http://www.ocpublicworks.com" alt="" target="_blank">
                    <img
                        src="assets/main/logos/oc_public_works_logo.png"
                        style="height: 35px"
                    />
                </a>
            </div>
            <div class="col g-0 text-center">
                <a
                    href="https://www.geosyntec.com/about"
                    alt=""
                    target="_blank"
                >
                    <img
                        src="assets/main/logos/Geosyntec_logo.png"
                        style="height: 55px"
                    />
                </a>
            </div>
            <div class="col g-0 text-center">
                <a
                    href="https://esassoc.com/"
                    alt="Environmental Science Associates"
                    target="_blank"
                >
                    <img
                        src="assets/main/logos/ESA-logo-white.png"
                        style="height: 75px"
                    />
                </a>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 text-center">
                <p>
                    The program is free software; you can redistribute it and/or
                    modify it under the terms of the
                    <a href="https://www.gnu.org/licenses/agpl-3.0.en.html"
                        >GNU Affero General Public License</a
                    >.<br />
                    Source code is available on
                    <a href="https://github.com/sitkatech/nebula">GitHub</a>.
                    Designed, Built, and Maintained by
                    <a href="https://esassoc.com/"
                        >Environmental Science Associates</a
                    >
                </p>
            </div>
        </div>
    </div>
</footer>
