<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <h2>Page Not Found</h2>
        <p class="lead">You are currently not authorized to view this page.</p>
    </div>
</div>
<div class="container my-sm-4">
    <!-- <app-alert-display></app-alert-display> -->
    <div class="col-md-6 offset-md-3"></div>
</div>
