<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading">
    <ng-container *ngIf="!isEditing">
        <div
            *ngIf="canEdit"
            class="customRichTextContent"
            style="position: relative; min-height: 60px"
        >
            <div class="hoverEditButton">
                <div style="margin-top: 10px">
                    <button
                        type="button"
                        class="btn btn-nebula"
                        (click)="enterEdit()"
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div
                *ngIf="!emptyContent"
                [innerHtml]="customRichTextContent"
            ></div>

            <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
        </div>
        <div *ngIf="!canEdit" [innerHtml]="customRichTextContent"></div>
    </ng-container>

    <div *ngIf="isEditing">
        <editor
            #tinyMceEditor
            [init]="tinyMceEditor | tinyMceConfig"
            [(ngModel)]="editedContent"
        >
        </editor>
        <button
            type="button"
            class="btn btn-primary mt-1 me-1"
            (click)="saveEdit()"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-secondary mt-1"
            (click)="cancelEdit()"
        >
            Cancel
        </button>
    </div>
</div>
