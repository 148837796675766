<div class="breadcrumb-container">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-empty">
                <li class="breadcrumb-item active" aria-current="page">
                    Labels and Definitions
                </li>
            </ol>
        </nav>
    </div>
</div>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Labels and Definitions</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
</div>
<div class="container">
    <div class="table-responsive">
        <ag-grid-angular
            #fieldDefinitionsGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [pagination]="true"
            [paginationPageSize]="100"
        >
        </ag-grid-angular>
    </div>
</div>
