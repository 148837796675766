/**
 * Nebula.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders,
  HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CustomRichTextDto } from '../model/custom-rich-text-dto';

import { BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class CustomRichTextService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
     * 
     * 
     * @param customRichTextTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public customRichTextCustomRichTextTypeIDGet(customRichTextTypeID: number, observe?: 'body', reportProgress?: boolean): Observable<CustomRichTextDto>;
  public customRichTextCustomRichTextTypeIDGet(customRichTextTypeID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomRichTextDto>>;
  public customRichTextCustomRichTextTypeIDGet(customRichTextTypeID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomRichTextDto>>;
  public customRichTextCustomRichTextTypeIDGet(customRichTextTypeID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (customRichTextTypeID === null || customRichTextTypeID === undefined) {
      throw new Error('Required parameter customRichTextTypeID was null or undefined when calling customRichTextCustomRichTextTypeIDGet.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CustomRichTextDto>(`${this.basePath}/customRichText/${encodeURIComponent(String(customRichTextTypeID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
  }

  /**
     * 
     * 
     * @param customRichTextTypeID 
     * @param customRichTextDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public customRichTextCustomRichTextTypeIDPut(customRichTextTypeID: number, customRichTextDto?: CustomRichTextDto, observe?: 'body', reportProgress?: boolean): Observable<CustomRichTextDto>;
  public customRichTextCustomRichTextTypeIDPut(customRichTextTypeID: number, customRichTextDto?: CustomRichTextDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomRichTextDto>>;
  public customRichTextCustomRichTextTypeIDPut(customRichTextTypeID: number, customRichTextDto?: CustomRichTextDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomRichTextDto>>;
  public customRichTextCustomRichTextTypeIDPut(customRichTextTypeID: number, customRichTextDto?: CustomRichTextDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (customRichTextTypeID === null || customRichTextTypeID === undefined) {
      throw new Error('Required parameter customRichTextTypeID was null or undefined when calling customRichTextCustomRichTextTypeIDPut.');
    }


    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<CustomRichTextDto>(`${this.basePath}/customRichText/${encodeURIComponent(String(customRichTextTypeID))}`,
      customRichTextDto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
  }

}
