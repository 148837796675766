<div class="breadcrumb-container">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb"></ol>
        </nav>
    </div>
</div>

<div class="container">
    <h2>Create New Custom Page</h2>
    <p class="lead">
        This page allows you to create a new custom page. Select a menu category
        for the custom page to reside in and which roles will be able to view
        the page. Administrators have viewable rights on all custom pages by
        default. The editor allows rich text editing capabilities as well as
        image uploads.
    </p>
</div>
<div class="container mt-sm-4" *ngIf="model">
    <app-alert-display></app-alert-display>

    <form
        (ngSubmit)="onSubmit(createNewCustomPageForm)"
        #createNewCustomPageForm="ngForm"
    >
        <div class="row">
            <div class="col-sm-12">
                <div class="form-horizontal">
                    <div class="form-group">
                        <div class="col-sm-2 control-label">
                            <label class="required">Menu</label>
                        </div>
                        <div class="col-sm-10">
                            <select
                                name="MenuItem"
                                class="form-select"
                                [(ngModel)]="model.MenuItemID"
                                required
                            >
                                <option selected disabled [value]="">
                                    Choose One
                                </option>
                                <option
                                    *ngFor="let menuItem of menuItems"
                                    [ngValue]="menuItem.MenuItemID"
                                >
                                    {{ menuItem.MenuItemDisplayName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-2 control-label">
                            <label class="required">Page Name</label>
                        </div>
                        <div class="col-sm-10">
                            <input
                                type="text"
                                class="form-control"
                                name="CustomPageDisplayName"
                                placeholder="Page Name"
                                required
                                [(ngModel)]="model.CustomPageDisplayName"
                                (ngModelChange)="slugifyPageName($event)"
                            />
                        </div>
                        <div
                            class="mt-2"
                            *ngIf="!validPageName(model.CustomPageDisplayName)"
                        >
                            <div class="col-sm-10">
                                <div class="alert alert-warning">
                                    Page name must consist of letters, numbers,
                                    spaces, underscores(_), and hyphens(-) only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-2 control-label">
                            <label class="required">Vanity URL</label>
                        </div>
                        <div class="col-sm-10">
                            <input
                                type="text"
                                class="form-control"
                                name="CustomPageVanityUrl"
                                placeholder="Vanity URL"
                                required
                                [(ngModel)]="model.CustomPageVanityUrl"
                            />
                        </div>
                        <div
                            class="mt-2"
                            *ngIf="!validVanityUrl(model.CustomPageVanityUrl)"
                        >
                            <div class="col-sm-10">
                                <div class="alert alert-warning">
                                    Vanity URL must consist of letters, numbers,
                                    underscores(_), and hyphens(-) only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-2 control-label">
                            <label>Viewable By</label>
                        </div>
                        <div
                            class="form-group form-check"
                            *ngFor="let role of roles"
                        >
                            <input
                                type="checkbox"
                                (change)="onViewableRolesChange(role.RoleID)"
                                [checked]="
                                    model.ViewableRoleIDs.includes(role.RoleID)
                                "
                                class="form-check-input"
                                name="ViewableRoleIDs{{ role.RoleID }}"
                                [value]="role.RoleID"
                                #viewableRolesCheckBox{{role.RoleID}}
                                id="viewableRolesCheckBox{{ role.RoleID }}"
                            />
                            <label
                                class="form-check-label"
                                for="viewableRolesCheckBox{{ role.RoleID }}"
                            >
                                {{ role.RoleDisplayName }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-2">
                <i class="fas fa-bolt"></i> Required field
            </div>
            <div class="col-xs-12 col-sm-10 text-end">
                <button
                    type="submit"
                    class="btn btn-nebula"
                    [disabled]="
                        isLoadingSubmit ||
                        !createNewCustomPageForm.form.valid ||
                        !validVanityUrl(model.CustomPageVanityUrl) ||
                        !validPageName(model.CustomPageDisplayName)
                    "
                >
                    <span
                        *ngIf="isLoadingSubmit"
                        class="fa fa-spinner loading-spinner"
                    ></span>
                    Create
                </button>
                <a class="btn btn-secondary ms-1" routerLink="/custom-pages"
                    >Cancel</a
                >
            </div>
        </div>
    </form>
</div>
