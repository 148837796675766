//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Role]

export enum RoleEnum {
  Admin = 1,
  DataExplorer = 2,
  Unassigned = 3,
  Disabled = 4
}
