<div class="mt-2" *ngIf="alerts.length > 0">
    <ngb-alert
        *ngFor="let alert of alerts"
        [type]="alert.context"
        [dismissible]="alert.dismissable"
        (closed)="closeAlert(alert)"
    >
        <span [innerHTML]="alert.message"></span>
    </ngb-alert>
</div>
