<nav class="navbar navbar-nebula navbar-expand-lg navbar-light stroke">
    <a href="https://esassoc.com/" class="navbar-brand" target="_blank">
        <img src="{{ leadOrganizationLogoSrc() }}" class="navbarLogo" />
    </a>
    <a
        routerLink="/"
        routerLinkActive="active"
        class="nav-link"
        *ngIf="!isHomepageCurrentPage()"
    >
        Home
    </a>
    <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mt-2 mt-lg-0">
            <!-- <a routerLink="/" class="nav-link" *ngIf="!isHomepageCurrentPage()">Home</a> -->
            <ng-container *ngIf="canSeeScenarioOptions()">
                <a routerLink="/time-series-analysis" class="nav-link">
                    Time Series Analysis
                </a>
                <a routerLink="/paired-regression-analysis" class="nav-link">
                    Paired Regression Analysis
                </a>
                <a routerLink="/diversion-scenario" class="nav-link">
                    Diversion Scenario
                </a>
                <li class="nav-item dropdown my-auto" routerLinkActive="active">
                    <a
                        href="#"
                        class="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Learn More
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a routerLink="/disclaimer/false" class="dropdown-item">
                            Disclaimer
                        </a>
                        <div
                            *ngIf="learnMorePages.length > 0"
                            class="dropdown-divider"
                        ></div>
                        <a
                            *ngFor="let learnMorePage of learnMorePages"
                            routerLink="/custom-pages/{{
                                learnMorePage.CustomPageVanityUrl
                            }}"
                            class="dropdown-item"
                        >
                            {{ learnMorePage.CustomPageDisplayName }}
                        </a>
                    </div>
                </li>
            </ng-container>
        </ul>
        <ul class="navbar-nav mt-2 mt-lg-0 me-sm-2">
            <li
                class="nav-item dropdown my-auto"
                routerLinkActive="active"
                *ngIf="isAdministrator()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Manage
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/users" class="dropdown-item"> Users </a>
                    <a
                        routerLink="/labels-and-definitions"
                        class="dropdown-item"
                    >
                        Labels and Definitions
                    </a>
                    <a routerLink="/custom-pages" class="dropdown-item">
                        Custom Pages
                    </a>
                </div>
            </li>
            <li
                class="nav-item"
                routerLinkActive="active"
                *ngIf="!isAuthenticated()"
            >
                <a
                    href="javascript:void(0);"
                    (click)="login()"
                    routerLinkActive="active"
                    class="nav-link"
                    >Sign In</a
                >
            </li>
            <li
                class="nav-item dropdown welcomeUser"
                routerLinkActive="active"
                *ngIf="isAuthenticated()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle pe-3"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Welcome {{ getUserName() }}
                </a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                >
                    <a routerLink="/help" class="dropdown-item"> Get Help </a>
                    <a
                        href="javascript:void(0);"
                        (click)="logout()"
                        class="dropdown-item"
                        >Sign Out</a
                    >
                </div>
            </li>
        </ul>
    </div>
</nav>
